import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes, isClientUserType, isPractitionerUserType } from '../../../../util/types';

import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';

import css from './TopbarDesktop.module.css';
import { createSlug, LISTING_PAGE_PARAM_TYPE_DRAFT } from '../../../../util/urlHelpers';

const SignupLink = () => {
  return (
    <NamedLink name="SignupPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );
};

const LoginLink = ({isPartnersLandingPage}) => {
  return (
    <NamedLink name={isPartnersLandingPage ? "SignupPractitionerPage" : "LoginPage"} className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
};

const PricesLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.prices" />
      </span>
    </NamedLink>
  );
};

const FaqLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.faq" />
      </span>
    </NamedLink>
  );
};
const ContactLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.contact" />
      </span>
    </NamedLink>
  );
};
const LookingTakeTreatmentLink = () => {
  return (
    <NamedLink name="LandingPage" className={css.topbarLink}>
      <span className={css.customTopbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.lookingForTreatment" />
      </span>
    </NamedLink>
  );
};

const LookingGiveTreatmentLink = () => {
  return (
    <NamedLink name="LandingPageClient" className={css.topbarLink}>
      <span className={css.customTopbarLinkLabel}>
        Are you holistic practitioner?
      </span>
    </NamedLink>
  );
};

const InboxLink = ({ notificationCount, currentUserHasListings }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  return (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

const ProfileMenu = ({ currentPage, currentUser, onLogout }) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const publicData = currentUser?.attributes?.profile?.publicData || {};
  const isProfileCreated = publicData?.isProfileCreated || false;
  const id = publicData?.profileId || null;
  const slug = createSlug('');
  const isClient = isClientUserType(currentUser);
  const isPractitioner = isPractitionerUserType(currentUser);

  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key='NewListingPage'>
          {isProfileCreated && isPractitioner ?
            (<NamedLink
              className={classNames(css.menuLink, currentPageClass('NewListingPage'))}
              name='ListingPage'
              params={{
                id,
                slug
              }}
            >
              <FormattedMessage id='TopbarDesktop.editProfileListing' />
            </NamedLink>) : isPractitioner ?
            (<NamedLink
              className={classNames(css.menuLink, currentPageClass('NewListingPage'))}
              name='NewListingPage'>
              <span className={css.menuItemBorder} />
              <FormattedMessage id='TopbarDesktop.createProfileListing' />
            </NamedLink>) : null}
        </MenuItem>
        <MenuItem key='NewTreatmentPage'>
          {isClient && <NamedLink
            className={classNames(css.menuLink, currentPageClass('NewTreatmentPage'))}
            name='ProfilePage'
            params={{ id: currentUser.id.uuid }}
          >
            <span className={css.menuItemBorder} />
            Your Profile
          </NamedLink>}
        </MenuItem>
        <MenuItem key='NewTreatmentPage'>
          {isPractitioner && <NamedLink
            className={classNames(css.menuLink, currentPageClass('NewTreatmentPage'))}
            name='NewTreatmentPage'>
            <span className={css.menuItemBorder} />
            <FormattedMessage id='TopbarDesktop.createTreatmentListing' />
          </NamedLink>}
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          {isPractitioner && <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
            name='ManageListingsPage'
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id='TopbarDesktop.yourListingsLink' />
          </NamedLink>}
        </MenuItem>
        {/* <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const TopbarDesktop = props => {
  const {
    className,
    config,
    customLinks,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    isLandingPage,
    isPartnersLandingPage,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [solidBackground, setSolidBackground] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const threshold = window.innerHeight * 0.8 - 56;
    if (scrollPosition > threshold) {
      setSolidBackground(true);
    } else {
      setSolidBackground(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(rootClassName || isLandingPage ? solidBackground ? css.root : css.rootLanding : css.root, className);

  const inboxLinkMaybe = authenticatedOnClientSide ? (
    <InboxLink
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu currentPage={currentPage} currentUser={currentUser} onLogout={onLogout} />
  ) : null;

  const signupLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLink />;
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink isPartnersLandingPage={isPartnersLandingPage} />;

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        linkToExternalSite={config?.topbar?.logoLink}
      />
      {/* <TopbarSearchForm
        className={classNames(css.searchLink, { [css.takeAvailableSpace]: giveSpaceForSearch })}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        onSubmit={onSearchSubmit}
        initialValues={initialSearchFormValues}
        appConfig={config}
      /> */}

      <div style={{ display: "flex", flexDirection: "row" }}>

        {/* <CustomLinksMenu
          currentPage={currentPage}
          customLinks={customLinks}
          intl={intl}
          hasClientSideContentReady={authenticatedOnClientSide || !isAuthenticatedOrJustHydrated}
        /> */}
        {/*<PricesLink />*/}
        {/*<FaqLink />*/}
        {/*<ContactLink />*/}
        {isPartnersLandingPage ?  <LookingTakeTreatmentLink /> : <LookingGiveTreatmentLink />}

        {inboxLinkMaybe}
        {profileMenuMaybe}
        {/* {signupLinkMaybe} */}
        {loginLinkMaybe}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
